import React from "react"
import Seo from "../components/seo"
import { Link } from "gatsby"
import tw from "twin.macro"
import { GlobeIcon, HomeIcon } from "@heroicons/react/outline"


const NotFoundPage = () => (
  <div css={tw`flex items-center justify-center h-screen bg-gray-100 text-gray-900 dark:(bg-gray-900 text-white)`}>
    <Seo title="404: Not found" />
    <div css={tw`prose mx-auto flex flex-col items-center`}>
      <GlobeIcon css={tw`w-64 h-64`} />
      <h1 css={tw`text-gray-900 dark:(text-white)`}>404: Not Found</h1>

      <Link to="/" css={tw`text-gray-900 dark:(text-white)`}>
        <HomeIcon css={tw`w-5 h-5 text-white inline-block mr-2.5`} />
        <span>Go to home</span>
      </Link>
      <p css={tw`text-gray-900 dark:(text-white)`}>You just hit a route that doesn&#39;t exist... the sadness.</p>
    </div>
  </div>
)

export default NotFoundPage
